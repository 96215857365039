/* Footer a Larghezza Completa */
.documents-table-container {
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  width: 100%;
}

.footer-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
  background-color: #f9f9f9;
  padding: 10px 20px;
  box-sizing: border-box;
}

.documents-table-container h1 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

.documents-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.documents-table th,
.documents-table td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.documents-table th {
  background-color: #0056b3;  /* Colore più scuro per maggiore contrasto */
  color: #ffffff;             /* Colore bianco per un miglior contrasto */
  font-weight: bold;
  padding: 15px;              /* Aumenta il padding per maggiore leggibilità */
  text-align: left;
}

.documents-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.documents-table tbody tr:hover {
  background-color: #e6f7ff;
}

.error-message {
  color: #d9534f;
  margin-bottom: 15px;
  font-weight: bold;
}

.documents-table td {
  color: #555;
}

/* Miglioramento Azioni di Modifica ed Eliminazione */
.action-button {
  background: none;
  border: none;
  cursor: pointer;
  margin-right: 8px;
  font-size: 1.2rem; /* Aumenta la dimensione per maggiore visibilità */
}

.edit-button {
  color: #007bff; /* Cambia il colore della matita per maggiore visibilità */
}

.delete-button {
  color: red;
}

.action-button:hover {
  opacity: 0.7;
}

/* DocumentsTable.css */
.action-button.pdf-button {
  color: #d32f2f; /* Rosso per PDF */
}

.action-button.xml-button {
  color: #1976d2; /* Blu per XML */
}

.action-button.delete-button {
  color: #9e9e9e; /* Grigio per il cestino */
}


/* Miglioramento Context Menu */
.context-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 999;
  width: 150px;
  border-radius: 5px;
}

.context-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.context-menu ul li {
  padding: 10px;
  cursor: pointer;
}

.context-menu ul li:hover {
  background-color: #f0f0f0;
}
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.8); /* Sfondo semitrasparente per evidenziare il caricamento */
  z-index: 1000; /* Assicurati che l'indicatore sia sopra altri elementi */
}
