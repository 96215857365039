.customer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  color: #333;
  margin-bottom: 20px;
}

.customer-table {
  width: 90%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.customer-table th,
.customer-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.customer-table th {
  background-color: #f2f2f2;
  color: #333;
}

.customer-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.customer-table tr:hover {
  background-color: #f1f1f1;
}

.customer-table .edit-btn,
.customer-table .delete-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 16px;
  margin-right: 8px;
}

.customer-table .edit-btn:hover {
  color: #007bff;
}

.customer-table .delete-btn:hover {
  color: #e74c3c;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin-top: 20px;
}

.footer-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.footer-button:hover {
  background-color: #0056b3;
}

.message {
  background-color: #dff0d8;
  color: #3c763d;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.error-message {
  background-color: #f2dede;
  color: #a94442;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
}
