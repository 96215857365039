
/* Stile per l'overlay del modale */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  /* Contenuto del modale */
  .modal-content {
    background-color: white;
    padding: 30px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 80vh;
    overflow-y: auto;
  }
  
  .modal-content h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  /* Contenitore per i pulsanti dei documenti */
  .document-buttons-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-bottom: 20px;
  }
  
  /* Stile per i pulsanti dei tipi di documento */
  .document-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 15px;
    font-size: 18px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    text-align: center;
  }
  
  .document-button:hover {
    background-color: #0056b3;
  }
  
  /* Stile per i pulsanti del modale (come Chiudi) */
  .modal-buttons {
    display: flex;
    justify-content: center;
  }
  
  .modal-buttons button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    background-color: #dc3545;
    color: white;
  }
  
  .modal-buttons button:hover {
    background-color: #b02a37;
  }
  