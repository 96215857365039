
  .filter-button {
    position: absolute; /* Posizionamento assoluto */
    top: 20px; /* Posizionamento dal margine superiore */
    right: 20px; /* Posizionamento dal margine destro */
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .filter-button:hover {
    background-color: #0056b3;
  }
  .footer-container{
    width: 100%;
  }