
.document-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    width: 400px; /* Inizia con larghezza zero */
    height: 100%;
    background-color: #ffffff;
    overflow: hidden; /* Nasconde il contenuto finché non è visibile */
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
    transition: width 0.9s ease; /* Transizione per apertura lenta */
    z-index: 20;
  }
  
  .document-sidebar .sidebar-content {
    width: 300px; /* Larghezza effettiva della sidebar */
    padding: 20px;
  }
 
  
  .close-button {
    background: #ff5c5c;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .close-button:hover {
    background: #e04c4c;
  }
  